import React from 'react';
import Layout from '../components/layout';
import * as styles from './index.module.css';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const IndexPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Marcian CX</h1>
          <p>Philosophy, art, and kinetics in pursuit of the masculine mandate.</p>
        </div>
      </section>

      <section className="content pt-16">
        <div className="container mx-auto px-4">
          <article>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Link to="/e-books/masculine-magnetism" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <StaticImage 
                    src="../images/masculine-magnetism-cover.jpg"
                    alt="Masculine Magnetism Book Cover"
                    className="w-full rounded-lg"
                  />
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Masculine Magnetism</h2>
                    <p className="text-gray-700 text-base">
                      Charisma is not an inborn trait. It is a skill that can be built, developed, and mastered. 
                      The 30-Day Masculine Magnetism Protocol is designed to rewire your brain, body, and behavior 
                      to make confidence, presence, and influence your natural state.
                    </p>
                  </div>
                </div>
              </Link>

              <Link to="/poetry" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <StaticImage 
                    src="../images/angels.jpeg"
                    alt="Poetry Collection"
                    className="w-full rounded-lg"
                  />
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Poetry Collection</h2>
                    <p className="text-gray-700 text-base">
                      A collection of poems exploring themes of masculinity, spirituality, and the human experience. 
                      Through carefully crafted verse, these works examine the depths of human nature and our 
                      relationship with the divine.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
